<template>
  <span
    v-if="props.page.icon_type === 'emoji'"
  >{{
    props.page.icon
  }}</span>
  <img
    v-else-if="props.page.icon_type === 'file' || isUrl(props.page.icon)"
    class="w-4 h-4 inline"
    :src="props.page.icon"
    :alt="`icon of page ${props.page.label}`"
  >
  <Icon
    v-else
    name="heroicons:document"
    class="w-4 h-4 inline text-gray-500 dark:text-gray-300"
  />
</template>

<script setup>
import { isUrl } from '~/lib/utils.js'

const props = defineProps({
  page: Object
})
</script>
